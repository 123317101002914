import { PostulationType } from "@lib/utils/constants";
import {
  requestTypeDocuments,
  requestCivilStatus,
  requestGenders,
  requestCitySector,
  requestMainRoadLetter,
  requestMainRoad,
  sendPostulation,
  updatePostulation,
  requestPostulationMode,
  requestPension,
  requestReasonNotPaymentPension,
  requestPostulations,
  requestIsPlatformAvailability,
  requestAffiliationCompensationFund,
  requestEarnedSalaries,
  requestEducationalLevel,
  requestEps,
  requestPensionsFund,
  requestEthnicGroup,
  requestTypeVincuCompensationFund,
  requestPopulationType,
  requestVulnerabilityFactor,
  requestResidentialType,
  requestPostulationAdjustment,
  saveLocalPostulation,
  authLogin,
  requestFormation,
  requestDisabilityType,
  requestVulnerabilityCondition,
  requestLocality,
  requestEducationalLevelFomento,
  requestScheduleAssignment,
  sendScheduleAppointment,
  requestDocumentTypeFomento,
  requestGenderFomento,
  requestIndigenousReservation,
  requestIndigenousVillage,
  requestReceptionCenter,
  requestEnableFosfecForm,
  requestEnableFosfecAppointmentForm,
  requestEthnicGroupFomento,
  requestCapturingLayoffs,
  requestPostulationDocuments,
  requestValidationsByPostulation,
  requestPostulationBusinessDays,
  requestUnemployedWithoutAttention,
  requestCancelUnemployed,
  updatePostulationEditState,
  requestUserTrainingWorkShop,
  getDocumentByType,
  destroyDocumentFosfec,
  getDocumentsByPostulation,
  requestOffDaysFomento,
  requestScheduleMonthSelect,
  saveExpressAuthorizationCheckApi,
  requestPreValidator,
  requestNeighborhoodByLocality,
  requestPostulationsStates,
  sendRegisterVideoYoutubeFosfec,
  requestExistRegisterVideoPostulationFosfec,
} from "@lib/api";

import {
  loadTypeDocuments,
  loadTypeDocumentsError,
  loadTypeDocumentsSuccess,
} from "@lib/store/slices/typeDocuments";

import {
  loadGenders,
  loadGendersError,
  loadGendersSuccess,
} from "@lib/store/slices/gender";

import {
  loadPostulationMode,
  loadPostulationModeError,
  loadPostulationModeSuccess,
} from "@lib/store/slices/postulationMode";

import {
  loadCivilStatus,
  loadCivilStatusError,
  loadCivilStatusSuccess,
} from "@lib/store/slices/civilStatus";

import {
  loadCitySector,
  loadCitySectorError,
  loadCitySectorSuccess,
} from "@lib/store/slices/citySector";

import {
  loadMainRoadLetter,
  loadMainRoadLetterError,
  loadMainRoadLetterSuccess,
} from "@lib/store/slices/mainRoadLetter";

import {
  loadMainRoad,
  loadMainRoadError,
  loadMainRoadSuccess,
} from "@lib/store/slices/mainRoad";

import {
  loadAffiliationCompensationFund,
  loadAffiliationCompensationFundSuccess,
  loadAffiliationCompensationFundError,
} from "@lib/store/slices/affiliationCompensationFund";

import {
  loadEarnedSalaries,
  loadEarnedSalariesSuccess,
  loadEarnedSalariesError,
} from "@lib/store/slices/earnedSalaries";

import {
  loadEducationalLevel,
  loadEducationalLevelSuccess,
  loadEducationalLevelError,
} from "@lib/store/slices/educationalLevel";

import { loadEps, loadEpsSuccess, loadEpsError } from "@lib/store/slices/eps";

import {
  loadEthnicGroup,
  loadEthnicGroupSuccess,
  loadEthnicGroupError,
} from "@lib/store/slices/ethnicGroup";

import {
  loadEthnicGroupFomento,
  loadEthnicGroupFomentoSuccess,
  loadEthnicGroupFomentoError,
} from "@lib/store/slices/ethnicGroupFomento";

import {
  loadPensionsFund,
  loadPensionsFundSuccess,
  loadPensionsFundError,
} from "@lib/store/slices/pensionsFund";

import {
  loadPopulationType,
  loadPopulationTypeSuccess,
  loadPopulationTypeError,
} from "@lib/store/slices/populationType";

import {
  loadTypeVincuCompensationFund,
  loadTypeVincuCompensationFundSuccess,
  loadTypeVincuCompensationFundError,
} from "@lib/store/slices/TypeVincuCompensationFund";

import {
  loadVulnerabilityFactor,
  loadVulnerabilityFactorSuccess,
  loadVulnerabilityFactorError,
} from "@lib/store/slices/vulnerabilityFactor";

import {
  loadResidentialType,
  loadResidentialTypeSuccess,
  loadResidentialTypeError,
} from "@lib/store/slices/residentialType";

import {
  savePostulationStepOne,
  loadPostulationsSuccess,
  loadPostulationsError,
  sendingPostulationError,
  sendingPostulationSuccess,
  loadPostulationByType,
  sendStateAlertSuccess,
  sendStateAlertDelete,
  savePostulationStepThree,
  saveCompletePostulation,
  loadFosfecAvailability,
  loadFosfecAvailabilitySuccess,
  loadFosfecAvailabilityError,
  loadModalAvailability,
  savePostulationAdjustment,
  loadModalFosfecForm,
  loadEnableFosfecFormSuccess,
  loadEnableFosfecForm,
  loadEnableFosfecAppointmentForm,
  loadEnableFosfecAppointmentFormSuccess,
  loadFosfecAppointmentAvailabilityError,
  loadModalFosfecAppointmentForm,
  updatePostulationState,
  updatePostulationStateSuccess,
  updatePostulationStateError,
  updateDataSideMenu,
  setDataSideMenu,
  loadDocumentsByPostulation,
  loadDocumentsByPostulationSuccess,
  loadDocumentsByPostulationError,
  sendExpressAuthorizationCheck,
  loadPostulationStatesyType,
  loadPostulationsStatesSuccess,
  setRegisterVideoYoutubeFosfec,
  setRegisterVideoYoutubeFosfecSuccess,
  setRegisterVideoYoutubeFosfecError,
  loadExistRegisterVideoPostulationFosfec,
  existRegisterVideoPostulationFosfecSuccess,
  existRegisterVideoPostulationFosfecError,
} from "@lib/store/slices/postulation";

import {
  loadFormations,
  loadFormationsSuccess,
  loadFormationsError,
} from "@lib/store/slices/formation";

import {
  loadDisabilityTypes,
  loadDisabilityTypesSuccess,
  loadDisabilityTypesError,
} from "@lib/store//slices/disabilityType";

import {
  loadVulnerabilityConditions,
  loadVulnerabilityConditionsSuccess,
  loadVulnerabilityConditionError,
} from "@lib/store//slices/vulnerabilityCondition";

import {
  loadLocalities,
  loadLocalitiesSuccess,
  loadLocalitiesError,
} from "@lib/store//slices/locality";

import {
  loadEducationalLevelsFomento,
  loadEducationalLevelsFomentoSuccess,
  loadEducationalLevelsFomentoError,
} from "@lib/store/slices/educationalLevelFomento";

import {
  loadScheduleAssignments,
  loadScheduleAssignmentsSuccess,
  loadScheduleAssignmentsError,
  loadScheduleAssignmentsDate,
  loadScheduleMonthSelect,
  loadScheduleMonthSelectSuccess,
} from "@lib/store/slices/scheduleAssignments";

import {
  sendingScheduleAppointmentSuccess,
  sendingScheduleAppointmentError,
  saveScheduleAppointmentStepOne,
  loadMessageSuccess,
  loadMessageIdError,
  loadMessageDisponibilityError,
  loadUnemployedWithoutAttentionSuccess,
  loadUnemployedWithoutAttentionError,
  loadUnemployedWithoutAttention,
  cancelUnemployedWithoutAttentionSuccess,
  cancelUnemployedWithoutAttentionError,
  cancelUnemployedWithoutAttention,
  loadUserTrainingWorkShopSuccess,
  loadUserTrainingWorkShopError,
  loadMessageHourDateValidation,
  loadMessageSendPostulationError,
} from "@lib/store/slices/unemployed";

import {
  loadTypeDocumentsFomento,
  loadTypeDocumentsFomentoError,
  loadTypeDocumentsFomentoSuccess,
} from "@lib/store/slices/typeDocumentsFomento";

import {
  loadGendersFomento,
  loadGendersFomentoError,
  loadGendersFomentoSuccess,
} from "@lib/store/slices/genderFomento";

import Router from "next/router";

import { login, loginError, loginSuccess } from "@lib/store/slices/auth";

import {
  getPostulation,
  getAuth,
  getFormation,
  getDisabilityType,
  getVulnerabilityCondition,
  getLocality,
  getScheduleAssignments,
  getUnemployed,
  getDocumentation,
  getValidation,
  getPostulationBusinessDays,
  getNeighborhood,
} from "@lib/store/selectors/index";

import { all, call, put, select, takeLatest } from "redux-saga/effects";

import { emptyPostulation, formatCreateDtoreateModalMessage } from "@lib/utils/helpers";
import {
  loadPension,
  loadPensionError,
  loadPensionSuccess,
} from "@lib/store/slices/Pension";
import {
  loadReasonNotPaymentPension,
  loadReasonNotPaymentPensionError,
  loadReasonNotPaymentPensionSuccess,
} from "@lib/store/slices/reasonNotPaymentPension";
import {
  loadIndigenousReservation,
  loadIndigenousReservationError,
  loadIndigenousReservationSuccess,
} from "../slices/indigenousReservation";
import {
  loadIndigenousVillage,
  loadIndigenousVillageError,
  loadIndigenousVillageSuccess,
} from "../slices/indigenousVillage";
import {
  loadReceptionCenter,
  loadReceptionCenterError,
  loadReceptionCenterSuccess,
} from "../slices/receptionCenter";
import {
  loadCapturingLayoffsEntityError,
  loadCapturingLayoffsEntityStatus,
  loadCapturingLayoffsEntitySuccess,
} from "../slices/capturingLayoffsEntity";
import {
  loadDocumentation,
  loadDocumentationError,
  loadDocumentationSuccess,
  loadDocumentByType,
  loadDocumentByTypeSuccess,
  loadDocumentByTypeError,
  deleteDocumentPostulation,
  deleteDocumentPostulationSuccess,
  deleteDocumentPostulationError,
} from "../slices/documentation";
import {
  loadValidation,
  loadValidationError,
  loadValidationSuccess,
} from "../slices/validation";
import {
  loadPostulationBusinessDays,
  loadPostulationBusinessDaysError,
  loadPostulationBusinessDaysSuccess,
} from "../slices/postulationBusinessDays";
import {
  loadOffDays,
  loadOffDaysError,
  loadOffDaysSuccess,
} from "../slices/offDays";
import jwtDecode from "jwt-decode";
import { AccessToken } from "../types";
import {
  loadPreValidator,
  loadPreValidatorError,
  loadPreValidatorSuccess,
} from "../slices/preValidator";
import { loadNeighborhoodByLocality, loadNeighborhoodError, loadNeighborhoodSuccess } from "../slices/neighborhood";

//tipo documento
function* fetchTypeDocuments() {
  try {
    const { data } = yield requestTypeDocuments();

    yield put(loadTypeDocumentsSuccess(data));
  } catch (error) {
    yield put(loadTypeDocumentsError((error as Error).message));
  }
}

// Estado civil
function* fetchCivilStatus() {
  try {
    const { data } = yield requestCivilStatus();
    yield put(loadCivilStatusSuccess(data));
  } catch (error) {
    yield put(loadCivilStatusError((error as Error).message));
  }
}

//generos
function* fetchGenders() {
  try {
    const { data } = yield requestGenders();

    yield put(loadGendersSuccess(data));
  } catch (error) {
    yield put(loadGendersError((error as Error).message));
  }
}

//sector de la ciudad
function* fetchCitySector() {
  try {
    console.log("fetchCitySector");

    const { data } = yield requestCitySector();

    yield put(loadCitySectorSuccess(data));
  } catch (error) {
    yield put(loadCitySectorError((error as Error).message));
  }
}

//Sufijo
function* fetchMainRoadLetter() {
  try {
    console.log("fetchMainRoad");

    const { data } = yield requestMainRoadLetter();

    yield put(loadMainRoadLetterSuccess(data));
  } catch (error) {
    yield put(loadMainRoadLetterError((error as Error).message));
  }
}

//calle principal
function* fetchMainRoad() {
  try {
    console.log("fetchMainRoad");

    const { data } = yield requestMainRoad();

    yield put(loadMainRoadSuccess(data));
  } catch (error) {
    yield put(loadMainRoadError((error as Error).message));
  }
}

/**Sync postulation */
function* syncPostulation() {
  try {
    const {
      postulationForm,
      postulationType,
      forwardTo,
      postulationId,
      formState,
      actionSave,
    } = yield select(getPostulation);

    if (postulationId === "") {
      const { data, status } = yield sendPostulation(
        { form: postulationForm },
        postulationType,
        forwardTo
      );

      if (status === 201) {
        switch (actionSave) {
          case "success":
            yield put(sendStateAlertSuccess(true));
            break;
          case "delete":
            yield put(sendStateAlertDelete(true));
            break;
        }
      }

      yield put(sendingPostulationSuccess(data));
    } else {
      const { data, status } = yield updatePostulation(postulationId, {
        form: postulationForm,
        form_state: formState,
        step: forwardTo,
      });

      if (status === 200) {
        switch (actionSave) {
          case "success":
            yield put(sendStateAlertSuccess(true));
            break;
          case "delete":
            yield put(sendStateAlertDelete(true));
            break;
        }
      }

      yield put(sendingPostulationSuccess(data));
    }
  } catch (error) {
    yield put(sendingPostulationError((error as Error).message));
  }
}

//get postulations
function* fetchPostulations() {
  try {
    const { postulationType } = yield select(getPostulation);

    const { data, status } = yield requestPostulations(postulationType);

    if (data.length > 0) {
      const { form, id, step, management_date } = data[0];

      console.log("fetchPostulations requestPostulations", data);

      yield put(
        loadPostulationsSuccess({
          postulation: data,
          form: form,
          id: id,
          step: step,
          managementDate: management_date,
        })
      );
    } else {
      yield put(
        loadPostulationsSuccess({
          postulation: data,
          form: emptyPostulation(),
          id: "",
          step: "1",
          managementDate: "",
        })
      );
    }

    if (status == 200) {
      yield put(loadFosfecAvailability(postulationType));
    }
  } catch (error) {
    yield put(loadPostulationsError((error as Error).message));
  }
}

//Modo de Postulación
function* fetchPostulationMode() {
  try {
    const { data } = yield requestPostulationMode();

    yield put(loadPostulationModeSuccess(data));
  } catch (error) {
    yield put(loadPostulationModeError((error as Error).message));
  }
}

//pension
function* fetchPension() {
  try {
    const { data } = yield requestPension();

    yield put(loadPensionSuccess(data));
  } catch (error) {
    yield put(loadPensionError((error as Error).message));
  }
}

function* fetchReasonNotPaymentPension() {
  try {
    const { data } = yield requestReasonNotPaymentPension();

    yield put(loadReasonNotPaymentPensionSuccess(data));
  } catch (error) {
    yield put(loadReasonNotPaymentPensionError((error as Error).message));
  }
}

function* fetchPlatformAvailability() {
  try {
    const { data, status } = yield requestIsPlatformAvailability(
      PostulationType.fosfec
    );
    console.log("fetchPlatformAvailability", data);

    yield put(loadFosfecAvailabilitySuccess(data));

    if (status == 200 && data.available == false) {
      yield put(loadModalAvailability(true));
    }
  } catch (error) {
    yield put(loadFosfecAvailabilityError((error as Error).message));
  }
}

function* fetchEnableFosfecForm() {
  try {
    const { idPostulan } = yield select(getPostulation);
    const { data, status } = yield requestEnableFosfecForm(idPostulan);
    console.log("fetchEnableFosfecForm", data);
    yield put(loadEnableFosfecFormSuccess(data));
    if (status == 200 && data.available == false) {
      yield put(loadModalFosfecForm(true));
    }
  } catch (error) {
    yield put(loadFosfecAvailabilityError((error as Error).message));
  }
}

function* fetchEnableFosfecAppointmentForm() {
  try {
    const { idPostulan } = yield select(getPostulation);
    const { data, status } = yield requestEnableFosfecAppointmentForm(
      idPostulan
    );
    console.log("fetchEnableFosfecForm", data);
    yield put(loadEnableFosfecAppointmentFormSuccess(data));
    if (status == 200 && data.available == false) {
      yield put(loadModalFosfecAppointmentForm(true));
    }
  } catch (error) {
    yield put(loadFosfecAppointmentAvailabilityError((error as Error).message));
  }
}

function* fetchLoginAccess() {
  try {
    const { accessToken } = yield select(getAuth);
    const { postulationType } = yield select(getPostulation);
    const { data, status } = yield authLogin(accessToken);

    yield put(loginSuccess(data.token));

    if (status == 200) {
      yield put(loadPostulationByType(postulationType));
    }
  } catch (error) {
    yield put(loginError((error as Error).message));
  }
}

function* fetchAffiliationCompensationFund() {
  try {
    const { data } = yield requestAffiliationCompensationFund();

    yield put(loadAffiliationCompensationFundSuccess(data));
  } catch (error) {
    yield put(loadAffiliationCompensationFundError((error as Error).message));
  }
}

function* fetchEarnedSalaries() {
  try {
    const { data } = yield requestEarnedSalaries();

    yield put(loadEarnedSalariesSuccess(data));
  } catch (error) {
    yield put(loadEarnedSalariesError((error as Error).message));
  }
}

function* fetchEducationalLevel() {
  try {
    const { data } = yield requestEducationalLevel();

    yield put(loadEducationalLevelSuccess(data));
  } catch (error) {
    yield put(loadEducationalLevelError((error as Error).message));
  }
}

function* fetchEps() {
  try {
    const { data } = yield requestEps();

    yield put(loadEpsSuccess(data));
  } catch (error) {
    yield put(loadEpsError((error as Error).message));
  }
}

function* fetchPensionsFund() {
  try {
    const { data } = yield requestPensionsFund();

    yield put(loadPensionsFundSuccess(data));
  } catch (error) {
    yield put(loadPensionsFundError((error as Error).message));
  }
}

function* fetchEthnicGroup() {
  try {
    const { data } = yield requestEthnicGroup();

    yield put(loadEthnicGroupSuccess(data));
  } catch (error) {
    yield put(loadEthnicGroupError((error as Error).message));
  }
}

function* fetchPopulationType() {
  try {
    const { data } = yield requestPopulationType();

    yield put(loadPopulationTypeSuccess(data));
  } catch (error) {
    yield put(loadPopulationTypeError((error as Error).message));
  }
}

function* fetchTypeVincuCompensationFund() {
  try {
    const { data } = yield requestTypeVincuCompensationFund();

    yield put(loadTypeVincuCompensationFundSuccess(data));
  } catch (error) {
    yield put(loadTypeVincuCompensationFundError((error as Error).message));
  }
}

function* fetchVulnerabilityFactor() {
  try {
    const { data } = yield requestVulnerabilityFactor();

    yield put(loadVulnerabilityFactorSuccess(data));
  } catch (error) {
    yield put(loadVulnerabilityFactorError((error as Error).message));
  }
}

function* fetchResidentialType() {
  try {
    const { data } = yield requestResidentialType();

    yield put(loadResidentialTypeSuccess(data));
  } catch (error) {
    yield put(loadResidentialTypeError((error as Error).message));
  }
}

function* syncPostulationAdjustment() {
  try {
    const { postulationId, forwardTo } = yield select(getPostulation);
    const { data, status } = yield requestPostulationAdjustment({
      postulation_id: postulationId,
    });

    yield put(sendingPostulationSuccess(data));
    //call next step if create is ok
    if (status === 201) {
      yield saveLocalPostulation(data.id);

      if (forwardTo) yield call(Router.push, forwardTo);
    }
  } catch (error) {
    yield put(sendingPostulationError((error as Error).message));
  }
}

function* fetchFormationState() {
  try {
    const { formation } = yield select(getFormation);
    const { data } = yield requestFormation();

    console.log("fetchFormation data", data);
    yield put(loadFormationsSuccess(data));
  } catch (error) {
    yield put(loadFormationsError((error as Error).message));
  }
}

function* fetchStateDisabilityType() {
  try {
    const { disabilityType } = yield select(getDisabilityType);
    const { data } = yield requestDisabilityType();

    console.log("fetchDisabilityType data", data);
    yield put(loadDisabilityTypesSuccess(data));
  } catch (error) {
    yield put(loadDisabilityTypesError((error as Error).message));
  }
}

function* fetchStateVulnerabilityCondition() {
  try {
    const { vulnerabilityCondition } = yield select(getVulnerabilityCondition);
    const { data } = yield requestVulnerabilityCondition();

    console.log("fetchVulnerabilityCondition data", data);
    yield put(loadVulnerabilityConditionsSuccess(data));
  } catch (error) {
    yield put(loadVulnerabilityConditionError((error as Error).message));
  }
}

function* fetchStateLocality() {
  try {
    const { locality } = yield select(getLocality);
    const { data } = yield requestLocality();

    console.log("fetchLocality data", data);
    yield put(loadLocalitiesSuccess(data));
  } catch (error) {
    yield put(loadLocalitiesError((error as Error).message));
  }
}

function* fetchEducationalLevelFomento() {
  try {
    const { data } = yield requestEducationalLevelFomento();

    yield put(loadEducationalLevelsFomentoSuccess(data));
  } catch (error) {
    yield put(loadEducationalLevelsFomentoError((error as Error).message));
  }
}

function* fetchScheduleAssignment() {
  try {
    const { timeDate } = yield select(getScheduleAssignments);
    const { data } = yield requestScheduleAssignment(timeDate);

    console.log(data, "requestScheduleAssignment");

    if (data.length > 0) {
      const { days, OffDays } = data[0];

      yield put(
        loadScheduleAssignmentsSuccess({
          data: data,
          days: days,
          OffDays: OffDays,
        })
      );
    } else {
      yield put(
        loadScheduleAssignmentsSuccess({ data: data, days: [], OffDays: false })
      );
    }
  } catch (error) {
    yield put(loadScheduleAssignmentsError((error as Error).message));
  }
}

function* syncScheduleAppointment() {
  try {
    const { postulationForm } = yield select(getUnemployed);

    const { data, status } = yield sendScheduleAppointment(postulationForm);
    console.log("sendingScheduleAppointmentSuccess", data);
    const { id } = data;

    yield put(
      sendingScheduleAppointmentSuccess({ data: data, registryId: id })
    );
    if (status == 201) {
      yield put(loadMessageSuccess(true));
    }
  } catch (error) {
    if (error.status == 409) {
      yield put(loadMessageIdError(true));
    }
    if (error.status == 400) {
      yield put(loadMessageDisponibilityError(true));
    }
    if (error.status == 403) {
      yield put(loadMessageHourDateValidation(true));
    }
    if (error.status === 500) {
      yield put(loadMessageSendPostulationError(true));
    }
    yield put(sendingScheduleAppointmentError((error as Error).message));
  }
}

//tipo documento fomento
function* fetchTypeDocumentsFomento() {
  try {
    const { data } = yield requestDocumentTypeFomento();

    yield put(loadTypeDocumentsFomentoSuccess(data));
  } catch (error) {
    yield put(loadTypeDocumentsFomentoError((error as Error).message));
  }
}

//generos fomento
function* fetchGendersFomento() {
  try {
    const { data } = yield requestGenderFomento();

    yield put(loadGendersFomentoSuccess(data));
  } catch (error) {
    yield put(loadGendersFomentoError((error as Error).message));
  }
}

function* fetchIndigenousReservation() {
  try {
    const { data } = yield requestIndigenousReservation();

    yield put(loadIndigenousReservationSuccess(data));
  } catch (error) {
    yield put(loadIndigenousReservationError((error as Error).message));
  }
}

function* fetchIndigenousVillage() {
  try {
    const { data } = yield requestIndigenousVillage();

    yield put(loadIndigenousVillageSuccess(data));
  } catch (error) {
    yield put(loadIndigenousVillageError((error as Error).message));
  }
}

function* fetchReceptionCenter() {
  try {
    const { data } = yield requestReceptionCenter();

    yield put(loadReceptionCenterSuccess(data));
  } catch (error) {
    yield put(loadReceptionCenterError((error as Error).message));
  }
}

function* fetchEthnicGroupFomento() {
  try {
    const { data } = yield requestEthnicGroupFomento();

    yield put(loadEthnicGroupFomentoSuccess(data));
  } catch (error) {
    yield put(loadEthnicGroupFomentoError((error as Error).message));
  }
}

function* fetchCapturingLayoffs() {
  try {
    const { data } = yield requestCapturingLayoffs();

    yield put(loadCapturingLayoffsEntitySuccess(data));
  } catch (error) {
    yield put(loadCapturingLayoffsEntityError((error as Error).message));
  }
}

/**fetchPostulationDocuments */
function* fetchPostulationDocuments() {
  try {
    const { postulationId } = yield select(getPostulation);
    const { data } = yield requestPostulationDocuments(postulationId);

    yield put(loadDocumentationSuccess(data));
  } catch (error) {
    yield put(loadDocumentationError((error as Error).message));
  }
}

/**fetchPostulationValidation */
function* fetchPostulationValidation() {
  try {
    const { postulationId } = yield select(getPostulation);
    const { data } = yield requestValidationsByPostulation(postulationId);

    yield put(loadValidationSuccess(data));
  } catch (error) {
    yield put(loadValidationError((error as Error).message));
  }
}
/**fetchPostulationBusinessDays */
function* fetchPostulationBusinessDays() {
  try {
    const { populationType } = yield select(getPostulation);
    const { data } = yield requestPostulationBusinessDays(populationType);

    yield put(loadPostulationBusinessDaysSuccess(data));
  } catch (error) {
    yield put(loadPostulationBusinessDaysError((error as Error).message));
  }
}
/**fetch fetchUnemployedWithoutAttention */
function* fetchUnemployedWithoutAttention() {
  try {
    const { documentId } = yield select(getUnemployed);
    const { data } = yield requestUnemployedWithoutAttention(documentId);

    yield put(loadUnemployedWithoutAttentionSuccess(data));
  } catch (error) {
    yield put(loadUnemployedWithoutAttentionError((error as Error).message));
  }
}

/**fetch fetchCancelUnemployedWithoutAttention */
function* fetchCancelUnemployedWithoutAttention() {
  try {
    const { unemployedWithoutAttention } = yield select(getUnemployed);
    yield requestCancelUnemployed(unemployedWithoutAttention.id);

    yield put(cancelUnemployedWithoutAttentionSuccess());
  } catch (error) {
    yield put(cancelUnemployedWithoutAttentionError((error as Error).message));
  }
}

/**fetch fetchUpdatePostulationState */
function* fetchUpdatePostulationState() {
  try {
    const { postulationId, stateName, postulationType } = yield select(
      getPostulation
    );

    const { data, status } = yield updatePostulationEditState(
      postulationId,
      stateName
    );

    if (status == 200) {
      yield put(loadPostulationByType(postulationType));
    }

    console.log("updatePostulationEditState requestPostulations", data);

    yield put(updatePostulationStateSuccess(data));
  } catch (error) {
    yield put(updatePostulationStateError((error as Error).message));
  }
}

function* fetchUserTrainingWorkShop() {
  try {
    const { idPostulan } = yield select(getPostulation);

    const { data, status } = yield requestUserTrainingWorkShop(idPostulan);

    const { training } = data[0];
    const { workshop } = data[1];

    console.log("fetchFindUserTrainingWorkShop", data);

    yield put(
      loadUserTrainingWorkShopSuccess({
        formation: training,
        workshop: workshop,
      })
    );
  } catch (error) {
    yield put(loadUserTrainingWorkShopError((error as Error).message));
  }
}

function* fetchLoadDocumentByType() {
  try {
    const { postulationId } = yield select(getPostulation);
    const { documentType } = yield select(getDocumentation);

    const { data, status } = yield getDocumentByType(
      postulationId,
      documentType
    );

    if (Object.keys(data).length > 0) {
      const { id } = data;
      yield put(loadDocumentByTypeSuccess({ id: id, exists: true }));
    } else {
      yield put(loadDocumentByTypeSuccess({ id: "", exists: false }));
    }
  } catch (error) {
    yield put(loadDocumentByTypeError((error as Error).message));
  }
}

function* fetchDeleteDocumentPostulation() {
  try {
    const { postulationId } = yield select(getPostulation);
    const { documentType, documentRecordId } = yield select(getDocumentation);

    const { data, status } = yield destroyDocumentFosfec(
      postulationId,
      documentType,
      documentRecordId
    );

    if (status == 200) {
      yield put(deleteDocumentPostulationSuccess(false));
    }
  } catch (error) {
    yield put(deleteDocumentPostulationError((error as Error).message));
  }
}

function* fetchDocumentsByPostulation() {
  try {
    const { postulationId } = yield select(getPostulation);
    const { data } = yield getDocumentsByPostulation(postulationId);
    yield put(loadDocumentsByPostulationSuccess(data));
  } catch (error) {
    yield put(loadDocumentsByPostulationError((error as Error).message));
  }
}

function* fetchOffDaysFomento() {
  try {
    const { data, status } = yield requestOffDaysFomento();
    if (status === 200) {
      yield put(loadOffDaysSuccess(data));
    }
  } catch (error) {
    yield put(loadOffDaysError((error as Error).message));
  }
}

function* fetchScheduleMonthSelect() {
  try {
    const { monthSelect } = yield select(getScheduleAssignments);
    const { data, status } = yield requestScheduleMonthSelect(monthSelect);
    if (status === 200) {
      yield put(loadScheduleMonthSelectSuccess(data));
    }
  } catch (error) {
    yield put(loadScheduleAssignmentsError((error as Error).message));
  }
}

function* fetchExpressAuthorizationCheck() {
  try {
    const { currentUser } = yield select(getAuth);
    const user: { user_id: number; email: string; username: string } =
      jwtDecode(currentUser);
    const { postulationForm, postulationId } = yield select(getPostulation);

    if (postulationForm.formMenu.stateExpressAuthorizationCheck != undefined) {
      const { data } = yield saveExpressAuthorizationCheckApi({
        table_id: postulationId,
        manager_user: user.user_id.toString(),
        user: user.username.toString(),
        changes: {
          stateExpressAuthorizationCheck:
            postulationForm.formMenu.stateExpressAuthorizationCheck,
          email: user.email,
          name:
            postulationForm.formOne.headName +
            " " +
            postulationForm.formOne.headLastName,
          identification: postulationForm.formOne.headNumberIdentification,
          identification_type: postulationForm.formOne.headTpDocument,
        },
      });
    }
  } catch (error) {
    console.error("Error Logs", error);
  }
}

function* fetchPreValidator() {
  try {
    const token = localStorage.getItem("keycloakTokenRefresh");
    if (!token) {
      throw new Error("Access token is missing");
    }

    const decodedToken = jwtDecode<AccessToken>(token);

    if (
      !decodedToken ||
      !decodedToken.identification_type ||
      !decodedToken.identification_number
    ) {
      throw new Error("Invalid token data");
    }

    const { identification_type, identification_number } = decodedToken;
    const { data } = yield requestPreValidator(
      identification_type,
      identification_number
    );

    const messages = formatCreateDtoreateModalMessage(data);

    yield put(loadPreValidatorSuccess({prevalidator: data, modalMessage:messages}));
  } catch (error) {
    yield put(loadPreValidatorError((error as Error).message));
  }
}

function* fetchLoadNeighborhoodByLocality() {
  try {
    const {localityId} = yield select(getNeighborhood);
    const {data, status} = yield requestNeighborhoodByLocality(localityId);

    if(status === 200){
      yield put(loadNeighborhoodSuccess(data));
    }
  } catch (error) {
    yield put(loadNeighborhoodError((error as Error).message));
  }
} 

function* fetchPostulationsStates() {
  try {
    const { postulationType } = yield select(getPostulation)
    const { data, status } = yield requestPostulationsStates(postulationType)
    console.log(data)
    if (data.length > 0) {
      const { id} =
        data[0]
      yield put(
        loadPostulationsStatesSuccess({
          postulationStates: data,
          id: id,
        }),
      )
    } else {
      yield put(
        loadPostulationsStatesSuccess({
          postulationStates: data,
          id: 'new',

        }),
      )
    }

    if (status == 200) {
      yield put(loadFosfecAvailability(postulationType))
    }
  } catch (error) {
    yield put(loadPostulationsError((error as Error).message))
  }
}

function* fetchRegisterVideoYoutubeFosfec() {
  try {
    const { createEndVideo } = yield select(getPostulation);
    const { data } = yield sendRegisterVideoYoutubeFosfec(createEndVideo);
    yield put(setRegisterVideoYoutubeFosfecSuccess(data));
  } catch (error) {
    yield put(setRegisterVideoYoutubeFosfecError((error as Error).message));
  }
}

function* fetchExistRegisterVideoPostulationFosfec() {
  try {
    const { postulationId, videoId } = yield select(getPostulation);
    const { data } = yield requestExistRegisterVideoPostulationFosfec(
      postulationId,
      videoId,
    );
    yield put(existRegisterVideoPostulationFosfecSuccess(data));
  } catch (error) {
    yield put(existRegisterVideoPostulationFosfecError((error as Error).message));
  }
}

function* rootSaga() {
  yield all([
    takeLatest(
      [
        savePostulationStepOne.type,
        savePostulationStepThree.type,
        saveCompletePostulation.type,
        updateDataSideMenu.type,
        setDataSideMenu.type,
      ],
      syncPostulation
    ),
    takeLatest(loadTypeDocuments.type, fetchTypeDocuments),
    takeLatest(loadGenders.type, fetchGenders),
    takeLatest(loadCivilStatus.type, fetchCivilStatus),
    takeLatest(loadCitySector.type, fetchCitySector),
    takeLatest(loadMainRoadLetter.type, fetchMainRoadLetter),
    takeLatest(loadMainRoad.type, fetchMainRoad),
    takeLatest(loadPostulationMode.type, fetchPostulationMode),
    takeLatest(loadPension.type, fetchPension),
    takeLatest(loadReasonNotPaymentPension.type, fetchReasonNotPaymentPension),

    takeLatest(
      loadAffiliationCompensationFund.type,
      fetchAffiliationCompensationFund
    ),
    takeLatest(loadPostulationStatesyType.type, fetchPostulationsStates),
    takeLatest(savePostulationAdjustment.type, syncPostulationAdjustment),
    takeLatest(loadEarnedSalaries.type, fetchEarnedSalaries),
    takeLatest(loadEducationalLevel.type, fetchEducationalLevel),
    takeLatest(loadEps.type, fetchEps),
    takeLatest(loadEthnicGroup.type, fetchEthnicGroup),
    takeLatest(loadPensionsFund.type, fetchPensionsFund),
    takeLatest(loadPopulationType.type, fetchPopulationType),
    takeLatest(
      loadTypeVincuCompensationFund.type,
      fetchTypeVincuCompensationFund
    ),
    takeLatest(loadVulnerabilityFactor.type, fetchVulnerabilityFactor),
    takeLatest(loadResidentialType.type, fetchResidentialType),

    takeLatest(loadPostulationByType.type, fetchPostulations),
    takeLatest(loadFosfecAvailability.type, fetchPlatformAvailability),
    takeLatest(login.type, fetchLoginAccess),
    takeLatest(loadFormations.type, fetchFormationState),
    takeLatest(loadDisabilityTypes.type, fetchStateDisabilityType),
    takeLatest(
      loadVulnerabilityConditions.type,
      fetchStateVulnerabilityCondition
    ),
    takeLatest(loadLocalities.type, fetchStateLocality),
    takeLatest(loadEducationalLevelsFomento.type, fetchEducationalLevelFomento),
    takeLatest(loadScheduleAssignmentsDate.type, fetchScheduleAssignment),
    takeLatest(saveScheduleAppointmentStepOne.type, syncScheduleAppointment),

    takeLatest(loadTypeDocumentsFomento.type, fetchTypeDocumentsFomento),
    takeLatest(loadGendersFomento.type, fetchGendersFomento),
    takeLatest(loadIndigenousReservation.type, fetchIndigenousReservation),
    takeLatest(loadIndigenousVillage.type, fetchIndigenousVillage),
    takeLatest(loadReceptionCenter.type, fetchReceptionCenter),
    takeLatest(loadEnableFosfecForm.type, fetchEnableFosfecForm),
    takeLatest(loadEnableFosfecForm.type, fetchUserTrainingWorkShop),
    takeLatest(
      loadEnableFosfecAppointmentForm.type,
      fetchEnableFosfecAppointmentForm
    ),
    takeLatest(loadEthnicGroupFomento.type, fetchEthnicGroupFomento),
    takeLatest(loadCapturingLayoffsEntityStatus.type, fetchCapturingLayoffs),
    takeLatest(loadDocumentation.type, fetchPostulationDocuments),
    takeLatest(loadValidation.type, fetchPostulationValidation),
    takeLatest(loadPostulationBusinessDays.type, fetchPostulationBusinessDays),
    takeLatest(
      loadUnemployedWithoutAttention.type,
      fetchUnemployedWithoutAttention
    ),
    takeLatest(
      loadUnemployedWithoutAttention.type,
      fetchUnemployedWithoutAttention
    ),
    takeLatest(
      cancelUnemployedWithoutAttention.type,
      fetchCancelUnemployedWithoutAttention
    ),
    takeLatest(updatePostulationState.type, fetchUpdatePostulationState),
    takeLatest(loadDocumentByType.type, fetchLoadDocumentByType),
    takeLatest(deleteDocumentPostulation.type, fetchDeleteDocumentPostulation),
    takeLatest(loadDocumentsByPostulation.type, fetchDocumentsByPostulation),
    takeLatest(loadOffDays.type, fetchOffDaysFomento),
    takeLatest(loadScheduleMonthSelect.type, fetchScheduleMonthSelect),
    takeLatest(saveCompletePostulation.type, fetchExpressAuthorizationCheck),
    takeLatest(loadPreValidator.type, fetchPreValidator),
    takeLatest(loadNeighborhoodByLocality.type, fetchLoadNeighborhoodByLocality),
    takeLatest(setRegisterVideoYoutubeFosfec.type, fetchRegisterVideoYoutubeFosfec),
    takeLatest(loadExistRegisterVideoPostulationFosfec.type, fetchExistRegisterVideoPostulationFosfec)
  ]);
}

export default rootSaga;
