import { store } from "@lib/store/store";
import axios from "axios";
import * as Sentry from "@sentry/nextjs";
import { convertToCamelCase, formatCreateDto } from "@lib/utils/helpers";
import { DataRegisterVideoYoutube, FormSchedulingItem } from "@lib/store/types";
//
const baseUrlFovis = () => {
  switch (process.env.environment) {
    case "production":
      return "https://autogestionsubsidio.api.comfandi.com.co/api/v1";
    case "quality":
      return "https://apifovisfosfec.subsidioscomfandi.link/api/v1";
    default:
      return "http://localhost:8080/api/v1";
  }
};

const baseUrlFomento = () => {
  switch (process.env.environment) {
    case "production":
      return "https://apifomento.subsidioscomfandi.com.co";
    case "quality":
      return "https://apifomento.subsidioscomfandi.com.co";
    default:
      return "http://localhost:3000";
  }
};

type PromiseCallback = (value?: any) => void;

import { PostulationFormItem } from "@lib/store/types/index";
import { basePath, noData } from "./utils/constants";

const login =
  (url: string, accessToken: string) =>
  (resolve: PromiseCallback, reject: PromiseCallback) => {
    axios
      .post(url, {
        access_token: `${accessToken}`,
        code: "",
      })
      .then((response) => resolve(response))
      .catch((error) => {
        console.error(error);
        Sentry.captureException(error);
        reject({
          message: "Something went wrong",
        });
      });
  };

const header = (formdata?: boolean) => {
  const state = store.getState();
  const user = state.authState.currentUser;
  return {
    Authorization: `Bearer ${user}`,
    ContentType: formdata ? "multipart/form-data" : "",
  };
};

const create =
  (url: string, data: any, formdata: boolean = false, queryParams: Record<string, string> = {}, headers: Record<string, string> = {}) =>
  (resolve: PromiseCallback, reject: PromiseCallback) => {
    axios
      .post(url, data, { headers: {...header(formdata), ...headers}, params: queryParams })
      .then((response) => resolve(response))
      .catch((error) => {
        console.error(error);
        Sentry.captureException(error);
        reject({
          message: "Something went wrong",
          status: error.response ? error.response.status : null,
        });
      });
  };

const get =
  (url: string) => (resolve: PromiseCallback, reject: PromiseCallback) => {
    axios
      .get(url, { headers: header() })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        Sentry.captureException(error);
        reject({
          message: "Something went wrong",
        });
      });
  };

const update =
  (url: string, data: any, formdata: boolean = false) =>
  (resolve: PromiseCallback, reject: PromiseCallback) => {
    axios
      .patch(url, data, { headers: header(formdata) })
      .then((response) => resolve(response))
      .catch((error) => {
        console.error(error);
        Sentry.captureException(error);
        reject({
          message: "Something went wrong",
        });
      });
  };

const destroy =
  (url: string, data: any, formdata: boolean = false) =>
  (resolve: PromiseCallback, reject: PromiseCallback) => {
    axios
      .delete(url, { headers: header(formdata), data: data })
      .then((response) => resolve(response))
      .catch((error) => {
        console.error(error);
        Sentry.captureException(error);
        reject({
          message: "Something went wrong",
        });
      });
  };

export const authLogin = (accessToken: string) =>
  new Promise(
    login(`${baseUrlFovis()}/users/rest-auth/keycloak/`, accessToken)
  );

export const requestTypeDocuments = () =>
  new Promise(get(`${baseUrlFovis()}/configurations/documents-tp/`));

export const requestGenders = () =>
  new Promise(get(`${baseUrlFovis()}/configurations/genders/`));

export const requestCivilStatus = () =>
  new Promise(get(`${baseUrlFovis()}/configurations/civil-status/`));

export const requestCitySector = () =>
  new Promise(get(`${baseUrlFovis()}/configurations/city-sector`));

export const requestMainRoad = () =>
  new Promise(get(`${baseUrlFovis()}/configurations/main-road`));

export const requestMainRoadLetter = () =>
  new Promise(get(`${baseUrlFovis()}/configurations/main-road-letter`));

export const sendPostulation = (
  postulation: {
    form: PostulationFormItem;
  },
  postulation_type: string,
  forwardTo: string
) =>
  new Promise(
    create(`${baseUrlFovis()}/postulations/postulate/`, {
      form: postulation.form,
      postulation_type: postulation_type,
      step: forwardTo,
    })
  );

export const destroyDocument = (
  postulation_id: string,
  documentation_type_id: string
) =>
  new Promise(
    destroy(`${baseUrlFovis()}/documentations/documents-dlt/`, {
      postulation: postulation_id,
      documentation_type: documentation_type_id,
    })
  );

export const updatePostulation = (
  postulationId: string,
  postulation: {
    form: PostulationFormItem;
    form_state: string;
    step: string;
  }
) =>
  new Promise(
    update(`${baseUrlFovis()}/postulations/${postulationId}/forms`, postulation)
  );

export const requestPostulationMode = () =>
  new Promise(get(`${baseUrlFovis()}/configurations/postulation-mode/`));

export const requestPension = () =>
  new Promise(get(`${baseUrlFovis()}/configurations/pension-fund/`));

export const requestReasonNotPaymentPension = () =>
  new Promise(
    get(`${baseUrlFovis()}/configurations/reason-for-non-payment-of-pension/`)
  );

export const requestPostulations = (postulationType: string) =>
  new Promise(get(`${baseUrlFovis()}/postulations/${postulationType}`));

export const getDocumentsByPostulation = (postulation_id: string) =>
  new Promise(
    get(`${baseUrlFovis()}/documentations/${postulation_id}/postulations`)
  );

export const getPostulationDocumentValidation = (
  documentation_type_id: string,
  postulation_id: string
) =>
  new Promise(
    create(`${baseUrlFovis()}/documentations/validate/`, {
      documentation_type: documentation_type_id,
      postulation: postulation_id,
    })
  );

export const sendPostulationDocument = (document: FormData) =>
  new Promise(create(`${baseUrlFovis()}/documentations/`, document, true));

export const requestLocations = (value: string) =>
  new Promise(get(`${baseUrlFovis()}/locations/?search=${value}`));

export const requestNeighborhood = (value: string) =>
  new Promise(
    get(`${baseUrlFovis()}/configurations/neighborhood/?search=${value}`)
  );

export const requestLocationsbyId = (locationId: string) =>
  new Promise(get(`${baseUrlFovis()}/locations/${locationId}/`));

export const requestIsPlatformAvailability = (flag: string) =>
  new Promise(
    get(
      `${baseUrlFovis()}/configurations/platform-has-disponibility-active/${flag}`
    )
  );

export const requestEnableFosfecForm = (idPostulan: string) =>
  new Promise(
    get(`${baseUrlFomento()}/unemployed/find-postulation/${idPostulan}`)
  );

export const requestEnableFosfecAppointmentForm = (idPostulan: string) =>
  new Promise(
    get(
      `${baseUrlFomento()}/unemployed/find-appointment-assignment/${idPostulan}`
    )
  );

export const requestAffiliationCompensationFund = () =>
  new Promise(
    get(`${baseUrlFovis()}/configurations/affiliation-compensation-fund/`)
  );

export const requestEarnedSalaries = () =>
  new Promise(get(`${baseUrlFovis()}/configurations/earned-salary/`));

export const requestEducationalLevel = () =>
  new Promise(get(`${baseUrlFovis()}/configurations/educational-level/`));

export const requestEps = () =>
  new Promise(get(`${baseUrlFovis()}/configurations/eps/`));

export const requestPensionsFund = () =>
  new Promise(get(`${baseUrlFovis()}/configurations/pension-fund/`));

export const requestEthnicGroup = () =>
  new Promise(get(`${baseUrlFovis()}/configurations/ethnic-group/`));

export const requestPopulationType = () =>
  new Promise(get(`${baseUrlFovis()}/configurations/population-type/`));

export const requestTypeVincuCompensationFund = () =>
  new Promise(
    get(`${baseUrlFovis()}/configurations/vinculation-type-compensation-fund/`)
  );

export const requestPostulationsStates = (postulationType: string) =>
  new Promise(get(`${baseUrlFovis()}/postulations/${postulationType}/states/`))
  

export const requestVulnerabilityFactor = () =>
  new Promise(get(`${baseUrlFovis()}/configurations/vulnerability-factor/`));

export const requestCapturingLayoffs = () =>
  new Promise(
    get(`${baseUrlFovis()}/configurations/capturing-layoffs-entities/`)
  );

export const requestResidentialType = () =>
  new Promise(get(`${baseUrlFovis()}/configurations/residential-type/`));

export const requestPostulationAdjustment = (postulation: {
  postulation_id: string;
}) =>
  new Promise(
    create(`${baseUrlFovis()}/postulations/adjustment/`, postulation)
  );

export const saveLocalPostulation = (value: string) =>
  Promise.resolve(localStorage.setItem("activePostulationId", value));

export const requestFormation = () =>
  new Promise(get(`${baseUrlFomento()}/formation`));

export const requestDisabilityType = () =>
  new Promise(get(`${baseUrlFomento()}/disability-type`));

export const requestVulnerabilityCondition = () =>
  new Promise(get(`${baseUrlFomento()}/vulnerability-condition`));

export const requestLocality = () =>
  new Promise(get(`${baseUrlFomento()}/locality`));

export const requestEducationalLevelFomento = () =>
  new Promise(get(`${baseUrlFomento()}/educational-level`));

export const requestScheduleAssignment = (timeDate: string) =>
  new Promise(
    update(`${baseUrlFomento()}/schedule-assignments/schedule`, {
      date: timeDate,
    })
  );



export const sendScheduleAppointment = (postulationForm: FormSchedulingItem) =>
  new Promise(
    create(`${baseUrlFomento()}/unemployed`, formatCreateDto(postulationForm))
  );

export const requestGenderFomento = () =>
  new Promise(get(`${baseUrlFomento()}/gender`));

export const requestDocumentTypeFomento = () =>
  new Promise(get(`${baseUrlFomento()}/document-type`));

export const requestIndigenousReservation = () =>
  new Promise(get(`${baseUrlFovis()}/configurations/indigenous-reservation/`));

export const requestIndigenousVillage = () =>
  new Promise(get(`${baseUrlFovis()}/configurations/indigenous-village/`));

export const requestReceptionCenter = () =>
  new Promise(get(`${baseUrlFovis()}/configurations/reception-center/`));

export const requestEthnicGroupFomento = () =>
  new Promise(get(`${baseUrlFomento()}/ethnic-group`));

export const requestPostulationDocuments = (postulationId: string) =>
  new Promise(
    get(`${baseUrlFovis()}/documentations/${postulationId}/postulations`)
  );

export const downloadDocumentPdf = async (documentId: string, name: string) => {
  try {
    // Hace una solicitud GET al endpoint de Django con el ID del documento
    const response = await fetch(
      `${baseUrlFovis()}/documentations/download/${documentId}`,
      {
        method: "GET",
        headers: header(),
      }
    );

    // Verifica si la solicitud fue exitosa
    if (response.ok) {
      // Convierte la respuesta a un blob
      const blob = await response.blob();
      // Crea un objeto URL para el blob
      const url = window.URL.createObjectURL(new Blob([blob]));
      // Crea un enlace invisible en el documento
      const link = document.createElement("a");
      // Establece el atributo href del enlace al objeto URL
      link.href = url;

      link.setAttribute("download", convertToCamelCase(name) + ".pdf");
      // Anexa el enlace al cuerpo del documento
      document.body.appendChild(link);
      // Simula un clic en el enlace para iniciar la descarga
      link.click();
      // Libera el objeto URL
      window.URL.revokeObjectURL(url);
    } else {
      // Maneja errores si la respuesta no es exitosa
      console.error("Error al descargar el archivo");
    }
  } catch (error) {
    // Maneja errores de red u otros errores
    console.error("Error de red:", error);
  }
};
export const requestValidationsByPostulation = (postulationId: string) =>
  new Promise(
    get(`${baseUrlFovis()}/validations/get_by_postulation_id/${postulationId}`)
  );

export const requestPostulationBusinessDays = (postulationType: string) =>
  new Promise(
    get(
      `${baseUrlFovis()}/configurations/postulation-business-days/${postulationType}`
    )
  );

export const requestUnemployedWithoutAttention = (document: string) =>
  new Promise(
    get(`${baseUrlFomento()}/unemployed/find-without-attention/${document}`)
  );

export const requestCancelUnemployed = (unemployedId: string) =>
  new Promise(
    update(`${baseUrlFomento()}/unemployed/cancel/${unemployedId}`, {})
  );

export const updatePostulationEditState = (
  postulationId: string,
  postulationState: string
) =>
  new Promise(
    update(`${baseUrlFovis()}/postulations/${postulationId}/edit-state`, {
      postulation_state: postulationState,
    })
  );

const getBlob =
  (url: string) => (resolve: PromiseCallback, reject: PromiseCallback) => {
    axios
      .get(url, {
        headers: header(),
        responseType: "blob", // Configura el tipo de respuesta a 'blob'
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        resolve(null);
      });
  };

export const getPostulationSavingFile = (
  documentation_type_id: string = noData,
  postulation_id: string,
  owner: string = noData
) =>
  new Promise(
    getBlob(
      `${baseUrlFovis()}/documentations/documentation-saving-file/${postulation_id}/${documentation_type_id}/${owner}`
    )
  );

export const updatePostulationDocument = (
  documentId: string,
  document: FormData,
  owner: string = noData
) =>
  new Promise(
    update(
      `${baseUrlFovis()}/documentations/${documentId}/${owner}/`,
      document,
      true
    )
  );

export const getDocumentByType = (
  postulation_id: string,
  documentation_type: string
) =>
  new Promise(
    get(
      `${baseUrlFovis()}/documentations/documentation-by-type/${postulation_id}/${documentation_type}/`
    )
  );

export const requestUserTrainingWorkShop = (idPostulan: string) =>
  new Promise(
    get(`${baseUrlFomento()}/unemployed/find-user-training/${idPostulan}`)
  );

export const destroyDocumentFosfec = (
  postulation_id: string,
  documentation_type_id: string,
  document_id: string
) =>
  new Promise(
    destroy(`${baseUrlFovis()}/documentations/delete-id/`, {
      postulation: postulation_id,
      documentation_type: documentation_type_id,
      document_id: document_id,
    })
  );

export const requestOffDaysFomento = () => 
  new Promise(get(`${baseUrlFomento()}/off-days`));

export const requestScheduleMonthSelect = (date: Date) =>
  new Promise(get(`${baseUrlFomento()}/schedule-assignments/schedules-by-month/${date}`));

export const saveExpressAuthorizationCheckApi = (createData: {
  table_id: string;
  manager_user: string;
  changes: {
    stateExpressAuthorizationCheck: boolean;
    email: string;
    name: string;
    identification: string;
    identification_type: string;
  };
  user: string;
}) =>
  new Promise(
    create(`${baseUrlFovis()}/configurations/generic-log/create`, {
      name_table: "postulations.postulation",
      table_id: createData.table_id,
      manager_user: createData.manager_user,
      change_type: "EXPRESS_AUTHORIZATION",
      changes: createData.changes,
      user: createData.user,
    })
  );

export const requestPreValidator = async (
  documentType: string,
  document: string
) => {
  try {
    const response = await axios.post(
      `${basePath}/api/prevalidator`,
      {
        documentType,
        document,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response;
  } catch (error) {
    throw new Error((error as Error).message);
  }
};

export const requestNeighborhoodByLocality = (locailityId: string) => 
  new Promise(get(`${baseUrlFomento()}/neighborhood/locality/${locailityId}`));

export const requestTokenPrevalidator = (url: string, queryParams: Record<string, string>, headers: Record<string, string>) =>
  new Promise(create(url, undefined, false, queryParams, headers));

export const requestPrevalidator = (url: string, queryParams: Record<string, string>, headers: Record<string, string>) =>
  new Promise(create(url, undefined, false, queryParams, headers));

export const sendRegisterVideoYoutubeFosfec = (postulation: DataRegisterVideoYoutube) => 
  new Promise(create(`${baseUrlFovis()}/configurations/postulant-video/`, postulation));

export const requestExistRegisterVideoPostulationFosfec = (
  postulation_id: string,
  video_id: string,
) =>
  new Promise(get(`${baseUrlFovis()}/configurations/${postulation_id}/${video_id}/`));

