import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PreValidatorItem, PreValidatorState } from "../types";
import { emptyPreValidator } from "@lib/utils/helpers";

export const initialState: PreValidatorState = {
  prevalidator: emptyPreValidator(),
  loading_prevalidator: false,
  error: "",
  modalMessage: []
};

const preValidatorSlice = createSlice({
  name: "preValidator",
  initialState,
  reducers: {
    loadPreValidator: (state) => {
      state.loading_prevalidator = true;
      state.error = "";
    },
    loadPreValidatorSuccess: (
      state,
      { payload }: PayloadAction<{ 
        prevalidator: PreValidatorItem,
        modalMessage: string[]
      }>
    ) => {
      state.prevalidator = payload.prevalidator;
      state.modalMessage = payload.modalMessage;
      state.loading_prevalidator = false;
    },
    loadPreValidatorError: (state, { payload }: PayloadAction<string>) => {
      state.error = payload; 
      state.loading_prevalidator = false;
    },
  },
});

export const {
  loadPreValidator,
  loadPreValidatorSuccess,
  loadPreValidatorError,
} = preValidatorSlice.actions;

export default preValidatorSlice.reducer;