import {
  MasterItem,
  MasterDocumentTypes,
  MasterItemPath,
  PathsNavigation,
  MainVideoYoutube,
} from "@lib/store/types";

export const basePath = "/calisto";

export const disableButtonCreatePostulacion =
  process.env.DISABLE_BUTTON_CREATE_POSTULATION == "true" ? true : false;

export const disableButtonModifyPostulation =
  process.env.DISABLE_BUTTON_MODIFY_POSTULATION == "true" ? true : false;

export const defaultBaseSalaryCOP = 1423500;
// Days of validity certification savings
export const daysValidityCertification = 90;
export const dataDefault = [
  {
    id: "0b88aa0b-31ed-417e-a852-963629ebfe0c",
    label: "CALI, VALLE DEL CAUCA, Colombia",
  },

  {
    id: "8b00e78a-678a-4581-899e-11a0bf7bc004",
    label: "PALMIRA, VALLE DEL CAUCA, Colombia",
  },

  {
    id: "93160209-7635-41bc-b34a-0522d2738a52",
    label: "TULUA, VALLE DEL CAUCA, Colombia",
  },

  {
    id: "df79fff1-b8cb-4e1e-a839-47ae0f84b8fb",
    label: "JAMUNDI, VALLE DEL CAUCA, Colombia",
  },

  {
    id: "fbb90f2f-728a-43b0-b2f2-3a18fa3717c5",
    label: "GUADALAJARA DE BUGA, VALLE DEL CAUCA, Colombia",
  },
];

export enum StatesPostulation {
  prescribed = "d853834a-bbdc-41a1-9de8-3a41ec5745fb",
  reintegrate = "45401e44-ffb6-4b65-bc38-9cb5c2b2c9a9",
  voluntaryRetirement = "1de961c1-c29b-4ccc-8ec2-b733f0fc818b",
}

export const dataDefaultNeighborhood = [
  {
    id: "05e344ed-89b1-462b-bfbc-ab97fc2cf340",
    name: "SILOE",
  },

  {
    id: "07f3f661-3ef8-4aed-a16e-6d895e9cf573",
    name: "ALTOS NORMANDIA",
  },
  {
    id: "1165854f-cf76-4cc3-94fb-282b03822c0c",
    name: "BELLAVISTA",
  },
];

export const dataDefaultYesOrNot = [
  {
    id: "4feba185-d471-464a-8e35-de970ae14973",
    name: "SI",
  },

  {
    id: "6440cfb8-69f0-409a-ac7e-b2ac823588eb",
    name: "NO",
  },
];

export const formations = [
  {
    id: "6feba185-d471-464a-7e54-de970ae26853",
    name: "Habilidades Socio-emocionales",
  },
];

export enum SelectYesOrNot {
  Yes = "4feba185-d471-464a-8e35-de970ae14973",
  Not = "6440cfb8-69f0-409a-ac7e-b2ac823588eb",
}

export enum DesYesOrNot {
  Yes = "SI",
  Not = "NO",
}

export const DocumentTypes: Record<string, MasterDocumentTypes> = {
  IdentificationCard: {
    id: "46dc2e93-a62a-48eb-b51c-ca8c3d38dcd4",
    name: "Cedula de ciudadanía",
    abbreviation: "CC",
  },

  ForeignerIdentification: {
    id: "57f390ae-a7ef-4c8f-8158-df1ebcf28185",
    name: "Cedula de extranjeria",
    abbreviation: "CE",
  },

  MinorIdentification: {
    id: "4a1dcebf-d4c0-49d5-a707-0f392701d464",
    name: "Tarjeta de Identidad",
    abbreviation: "TI",
  },

  Passport: {
    id: "a9c36156-02fb-4112-a350-02e10e05e656",
    name: "Pasaporte",
    abbreviation: "PP",
  },

  SpecialResidencePermission: {
    id: "deb37cf5-1992-46cd-bc7e-4c9216235a6b",
    name: "Permiso Especial de Permanencia",
    abbreviation: "PEP",
  },
  TemporaryProtection: {
    id: "20c8418f-0703-4008-8ed3-dadc5243d6d6",
    name: "Permiso por Protección Temporal",
    abbreviation: "PPT",
  },
};

export const DocumentTypesFomento: Record<string, MasterDocumentTypes> = {
  IdentificationCard: {
    id: "393c3062-60ca-4df7-a622-bb28040d1a5b",
    name: "Cedula de ciudadanía",
    abbreviation: "CC",
  },

  DocumentNumberIdentification: {
    id: "df14259a-06e0-4bd9-9c39-fb954894a484",
    name: "Documento numero identificación",
    abbreviation: "DNI",
  },

  PermitTemporaryProtection: {
    id: "f10df94f-8dc3-478a-ab6b-0bc515913886",
    name: "Permiso protección temporal",
    abbreviation: "PPT",
  },

  ForeignerIdentification: {
    id: "4735f9ba-ce6b-472b-b61b-0537e9ac34f8",
    name: "Cedula de extranjeria",
    abbreviation: "CE",
  },

  MinorIdentification: {
    id: "fc3339f3-fd7b-48b1-b978-44c57d057fe7",
    name: "Tarjeta de Identidad",
    abbreviation: "TI",
  },
};

export enum Occupation {
  Employee = "60a08a8b-cc8e-4238-b0f7-e37176cb8b1d",
  Pensioner = "6510a64e-8a5c-44c5-b308-d09f90dfa97c",
  Independent = "5cbfc71f-f696-47eb-aa0a-c1bc41379433",
  Home = "58a8bdbc-07af-4924-a510-fac68f73bf10",
  Student = "279c9527-f917-4efa-be80-ec416cf5f977",
  Unemployed = "b840dc46-f7df-4112-a6eb-802ed0c109d9",
}

export enum CreditType {
  approved = "approved",
  preApproved = "preapproved",
  solidarityContribution = "solidarityContribution",
  territorialEntityContribution = "territorialEntityContribution",
  donations = "donations",
  anotherResources = "anotherResources",
}

export enum MinimumSalaryUnit {
  Zero = 0,
  Two = 2,
  Three = 3,
  Four = 4,
  Sixteen = 16,
  Eighteen = 18,
  Twenty = 20,
  TwentyTwo = 22,
  Thirty = 30,
  FiftyFive = 55,
  Seventy = 70,
}

export enum Salary {
  OneMinimumSalary = defaultBaseSalaryCOP,
  TwoMinimumSalary = defaultBaseSalaryCOP * MinimumSalaryUnit.Two,
  ThreeMinimumSalary = defaultBaseSalaryCOP * MinimumSalaryUnit.Three,
  FourMinimumSalary = defaultBaseSalaryCOP * MinimumSalaryUnit.Four,
  ThirtyMinimumSalary = defaultBaseSalaryCOP * MinimumSalaryUnit.Thirty,
  TwentyMinimumSalary = defaultBaseSalaryCOP * MinimumSalaryUnit.Twenty,
  TwentyTwoMinimumSalary = defaultBaseSalaryCOP * MinimumSalaryUnit.TwentyTwo,
  EighteenMinimumSalary = defaultBaseSalaryCOP * MinimumSalaryUnit.Eighteen,
  SixteenMinimunSalary = defaultBaseSalaryCOP * MinimumSalaryUnit.Sixteen,
  FiftyFiveMinimunSalary = defaultBaseSalaryCOP * MinimumSalaryUnit.FiftyFive,
  SeventyMinimumSalary = defaultBaseSalaryCOP * MinimumSalaryUnit.Seventy,
}

export enum Age {
  Adult = 18,
  OlderAdult = 65,
  MinimumApplyFosfec = 14,
}

export enum DocumentType {
  CertificateOfInactivityDependent = "03c01b96-29d3-4b82-8887-2e5575c6889f",
  CertificateOfInactivitySelfEmployed = "a9bdd352-9fe6-4d5f-88c8-100ba536ffbc",
  IdentityDocument = "45789fc9-ff8c-4d12-8e91-0e665ff6f709",
  CertificatePermitsForMinors = "793a3b5e-e0c8-4e4c-a83b-eca4fa1d5c91",
}

export enum ExcludeDocument {
  SapStackQuery = "e0aa17f7-3eb7-42a6-9455-ebb83987fe51",
  PQRS = "d1b6da60-a04a-48a5-8bdf-de46724b00d1",
  InstitutionalMail = "9a104ecc-cc7e-4421-ae27-7776fff9f4fb",
}

export enum AffiliateStateSap {
  Vigente = "001",
  Retirado = "002",
}

export const Zone: Record<string, MasterItem> = {
  Urban: { id: "bf8fa7c5-3bee-4810-973b-5bb29c73c43d", name: "U" },
  Rural: { id: "d73a685c-9673-4d68-9866-913e720e103a", name: "R" },
};

export enum PostulationType {
  sfv = "42997813-afe9-4720-a519-498cac162314",
  fosfec = "2a3f2d36-4e52-4f70-b49c-48ce441d9ec6",
  sbe = "97a3f8f5-2644-4836-aeb7-15e43f8371b2",
  lease = "93eca574-b375-4525-aa03-df6f18113b51",
}

export const SalaryLimitCity: Record<string, MasterItem> = {
  Cali: {
    id: "0b88aa0b-31ed-417e-a852-963629ebfe0c",
    name: "CALI, VALLE DEL CAUCA, Colombia",
  },

  Jamundi: {
    id: "df79fff1-b8cb-4e1e-a839-47ae0f84b8fb",
    name: "JAMUNDI, VALLE DEL CAUCA, Colombia",
  },

  Candelaria: {
    id: "4cf5bad5-56b7-4abc-b6c9-50775d243f34",
    name: "CANDELARIA, VALLE DEL CAUCA, Colombia",
  },

  PuertoTejada: {
    id: "6aec8dc8-1934-4ba1-8ba7-c0ee0bbba6bc",
    name: "PUERTO TEJADA, CAUCA, Colombia",
  },

  Yumbo: {
    id: "9f2a40b3-f3b1-4aa7-9c12-e18568a4cbaa",
    name: "YUMBO, VALLE DEL CAUCA, Colombia",
  },
};

export enum CivilStatus {
  Single = "60cdaa93-a7f2-4eb9-9e37-56a7c361a3f1",
}

export enum Relationship {
  spouse = "98ab3a94-ce58-400f-8dd2-ec3340634084",
}

export enum EthnicGroup {
  indigenous = "f5117d77-ace8-4011-8ae5-3f12f85b3171",
}

export enum RedirecTypeForm {
  scheduling = "scheduling",
  fosfec = "fosfec",
}

export enum PostulateMode {
  dependent = "f298c2cb-6d10-4b6d-b8f0-f9b821cd74f8",
  independent = "90df8fe6-e50a-4279-8059-21ee5858a033",
}

// CCF hace referencia a la caja de compensación
export enum CompensationFund {
  comfandi = "4c50e8dd-e63a-443d-94d7-c76ca622c47d",
}

export const isProduction = process.env.ENV;

export const mainMenuFosfec: MasterItemPath[] = [
  //datos información personal
  { id: "0", path: "/" },
  { id: "1", path: "/steps/Dashboard" },
  // { id: "2", path: "/fosfec/steps/ApplicationSummary" },
  { id: "2", path: "/steps/SelectReceivedUnemployment" },
  { id: "3", path: "/steps/SelectPostulationMode" },
  { id: "4", path: "/steps/InformationPersonalOne" },
  { id: "5", path: "/steps/InformationPersonalTwo" },
  { id: "6", path: "/steps/InformationPersonalThree" },
  { id: "7", path: "/steps/SelectPublicEmploymentService" },
  { id: "8", path: "/steps/InformationSocialSecurity" },
  { id: "9", path: "/steps/SelectPensionRequired" },
  { id: "10", path: "/steps/SelectSeveranceFund" },
  { id: "11", path: "/steps/SelectSavingsSeveranceProtection" },
  // { id: "12", path: "/fosfec/steps/DocumentList" },
  { id: "12", path: "/steps/ApplicationSummary" },
  { id: "13", path: "/steps/FinalVerificationProcess" },
];

export const statusBusinessDays: { REJECTED: string; REFUND: string } = {
  REJECTED: "Rechazo",
  REFUND: "Devolución",
};

export const timeAvailabilityStatus: { REJECTED: number; REFUND: number } = {
  REJECTED: 11,
  REFUND: 6,
};

export const noData = "empty";

export const newMainMenuFosfec: PathsNavigation = {
  GetStarted: {
    id: "0",
    path: "/",
  },
  Dashboard: {
    id: "1",
    path: "/steps/Dashboard",
  },
  ViewerYoutubeTipsRestrictionsSubsidy: {
    id: "2",
    path: "/steps/ViewerYoutubeTipsRestrictionsSubsidy",
  },
  InformationPersonalOneNew: {
    id: "3",
    path: "/steps/InformationPersonalOneNew",
  },
  InformationPersonalTwoNew: {
    id: "4",
    path: "/steps/InformationPersonalTwoNew",
  },
  InformationPersonalThreeNew: {
    id: "5",
    path: "/steps/InformationPersonalThreeNew",
  },
  PublicEmploymentServiceNew: {
    id: "6",
    path: "/steps/PublicEmploymentServiceNew",
  },
  SocialSecurityInformationNew: {
    id: "7",
    path: "/steps/SocialSecurityInformationNew",
  },
  PensionandSeveranceFundNew: {
    id: "8",
    path: "/steps/PensionandSeveranceFundNew",
  },
  SeveranceProtectionNew: {
    id: "9",
    path: "/steps/SeveranceProtectionNew",
  },
  ApplicationSummary: {
    id: "10",
    path: "/steps/ApplicationSummary",
  },
  FinalVerificationProcess: {
    id: "11",
    path: "/steps/FinalVerificationProcess",
  },
  EmployabilityRouteMenu: {
    id: "12",
    path: "/menu/EmployabilityRouteMenu",
  },
};

export const holidays: string[] = [
  "2024-01-01",
  "2024-01-08",
  "2024-03-25",
  "2024-03-28",
  "2024-03-29",
  "2024-05-01",
  "2024-05-13",
  "2024-06-03",
  "2024-06-10",
  "2024-07-01",
  "2024-07-20",
  "2024-08-07",
  "2024-08-07",
  "2024-08-19",
  "2024-10-14",
  "2024-11-04",
  "2024-11-11",
  "2024-12-08",
  "2024-12-25",
];

export const commune = [
  {
    id: "001",
    name: "1",
  },
  {
    id: "002",
    name: "2",
  },
  {
    id: "003",
    name: "3",
  },
  {
    id: "004",
    name: "4",
  },
  {
    id: "005",
    name: "5",
  },
  {
    id: "006",
    name: "6",
  },
  {
    id: "007",
    name: "7",
  },
  {
    id: "008",
    name: "8",
  },
  {
    id: "009",
    name: "9",
  },
  {
    id: "010",
    name: "10",
  },
  {
    id: "011",
    name: "11",
  },
  {
    id: "012",
    name: "12",
  },
  {
    id: "013",
    name: "13",
  },
  {
    id: "014",
    name: "14",
  },
  {
    id: "015",
    name: "15",
  },
  {
    id: "016",
    name: "16",
  },
  {
    id: "017",
    name: "17",
  },
  {
    id: "018",
    name: "18",
  },
  {
    id: "019",
    name: "19",
  },
  {
    id: "020",
    name: "20",
  },
  {
    id: "021",
    name: "21",
  },
  {
    id: "022",
    name: "22",
  },
];

export const stratum = [
  {
    id: "01",
    name: "1",
  },
  {
    id: "02",
    name: "2",
  },
  {
    id: "03",
    name: "3",
  },
  {
    id: "04",
    name: "4",
  },
  {
    id: "05",
    name: "5",
  },
  {
    id: "06",
    name: "6",
  },
];

export const stateEmployability = [
  {
    id: "01",
    name: "INDEPENDIENTE",
  },
  {
    id: "02",
    name: "DESEMPLEADO",
  },
  {
    id: "03",
    name: "EMPLEADO",
  },
];

export const Gender: Record<string, MasterItem> = {
  Man: {
    id: "cea6c913-db58-4c42-a228-51546d0926fc",
    name: "Hombre",
  },
  Woman: {
    id: "b0b0cd36-f4e0-4419-aa91-ba34d967e2c3",
    name: "Mujer",
  },
  Other: {
    id: "5566834f-5e29-4af0-b635-5667e0decbfb",
    name: "Indeterminado",
  },
};

export const GenderFomento: Record<string, MasterItem> = {
  Man: {
    id: "aca6bf60-d5f4-4837-b080-6bf9b43f7ace",
    name: "HOMBRE",
  },
  Woman: {
    id: "9a716d09-8afb-4a6b-9bed-1f563399c0e0",
    name: "MUJER",
  },
  Other: {
    id: "26363ece-1ff0-4ff0-9027-53e9e276820d",
    name: "OTRO",
  },
};

const specifyMaintenanceDate = true; // Cambiar a true si se necesita especificar la fecha

export const maintenanceStartTime: Date | null = specifyMaintenanceDate
  ? new Date("2024-08-23T12:00:00") // Definir las horas en formato 24 horas
  : null;

export const maintenanceEndTime: Date | null = specifyMaintenanceDate
  ? new Date("2024-08-23T13:15:00") // Definir las horas en formato 24 horas
  : null;

export enum ComplianceStatus {
  COMPLIES = "001",
  DOES_NOT_COMPLY = "002",
}

export const PrevalidatorStatus = [
  {
    state: "Documento No Encontrado",
    message:
      "El estado de tu afiliación a Comfandi se encuentra vigente o nunca has estado afiliado(a).",
  },
  {
    state: "No Cumple Aportes",
    message:
      "El porcentaje de aporte con el que cotizas a Comfandi, no es igual al 2% sobre tu ingreso mensual.",
  },
  {
    state: "Postulacion Abierta",
    message: "Cuentas con una solicitud vigente para este subsidio.",
  },
  {
    state: "Fallecido",
    message:
      "Tu registro en Comfandi se encuentra concluido por fallecimiento.",
  },
];

export const PrevalidatorOutputSuccess = "Consulta exitosa";

export const videoYoutube: MainVideoYoutube = {
  TipsRestrictionsSubsidy: {
    id: "3b5aa9f6-a5ce-4b31-9d85-239273349b4b",
    url: "https://www.youtube.com/watch?v=znOgNAxQU7Q&t=1s",
  },
};

export const sucursalVirtualLink = "https://www.sucursalcomfandi.com/";
